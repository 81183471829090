
export const projectReducer = (state = {
  cache: {},
  cacheRequests: {},
  queryResultsCache: {},
  orgItems: {},
  myProjects: [],
  newProjectForm:              {errors: {}, lastSubmit: {}},
  setProjectSettingsForm:      {errors: {}, lastSubmit: {}},
  installComponentForm:    {errors: {}, lastSubmit: {}},
  updateInstalledComponentForm:    {errors: {}, lastSubmit: {}},
  updateInstalledComponentRequests: {},
  runScheduledComponentRequests: {},
  deleteProjectForm:    {errors: {}, lastSubmit: {}},
  ready: false
}, action) => {

  switch(action.type){

    
    case 'SET_CURRENT_PROJECT':
      return {
        ...state,
        current: action.data
      }


    case 'UNSET_CURRENT_PROJECT':
      return {
        ...state,
        current: undefined
      }


    case 'PASS_PROFILE_PROJECTS_TO_CACHE':
      var cache = state.cache;

      var cache = state.cache;
      for(var i in action.data){
        for(var ii in action.data[i]){
          let p = action.data[i][ii];
          cache[p.id] = {
            ...cache[p.id],
            ...p
          };
        }
      }

      return {
        ...state,
        cache: cache
      }


    
    case 'REQUEST_CREATE_NEW_PROJECT':
      return {
        ...state,
        tryingToCreateNewProject: true,
        newProjectForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_PROJECT_FAIL':
       return {
        ...state,
        tryingToCreateNewProject: false,
        newProjectFail: new Date().getTime(),
        newProjectForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_PROJECT_SUCCESS':
      var cache = state.cache;
      cache[action.data.id] = action.data;

      var myProjects = state.myProjects;
      if(!myProjects[action.data.scope]) myProjects[action.data.scope] = [];
      myProjects[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myProjects: myProjects,
        tryingToCreateNewProject: false,
        newProjectSuccess: new Date().getTime(),
        newProjectForm: {errors: {}, lastSubmit: {}}
      }




      case 'REQUEST_DELETE_PROJECT':

      return {
        ...state,
        tryingToDeleteProject: true,
        deleteProjectForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_PROJECT_FAIL':
       return {
        ...state,
        tryingToDeleteProject: false,
        deleteProjectFail: new Date().getTime(),
        deleteProjectForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }
  
    case 'RECEIVE_DELETE_PROJECT_SUCCESS':
      
      var cache = state.cache;
      delete cache[action.data.id];
      
      return {
        ...state,
        cache: cache,
        tryingToDeleteProject: false,
        deleteProjectSuccess: new Date().getTime(),
        deleteProjectForm: {errors: {}, lastSubmit: {}}
      }
  
  
      
    
    case 'REQUEST_GET_MY_PROJECTS':
      return {
        ...state,
        tryingToGetMyProjects: true
      }

    case 'RECEIVE_GET_MY_PROJECTS_FAIL':
        return {
        ...state,
        tryingToGetMyProjects: false,
        getMyProjectsFail: new Date().getTime()
      }

    case 'RECEIVE_GET_MY_PROJECTS_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };

      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetMyProjects: false,
        getMyProjectsSuccess: new Date().getTime()
      }

  
    case 'REQUEST_GET_PROJECTS_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetProjectsByOrganization: true
      }

    case 'RECEIVE_GET_PROJECTS_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetProjectsByOrganization: false,
        getProjectsByOrganizationFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROJECTS_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      var orgItems = state.orgItems;
      for(var i in action.data){
        let p = action.data[i];
        cache[p.id] = {
          ...cache[p.id],
          ...p
        };

        if(!orgItems[p.scope]) orgItems[p.scope] = [];
        orgItems[p.scope].push(p.id);
      }

      return {
        ...state,
        orgItems: orgItems,
        cache: cache,
        ready: true,
        tryingToGetProjectsByOrganization: false,
        getProjectsByOrganizationSuccess: new Date().getTime()
      }


    case 'REQUEST_GET_PROJECT':

      var cacheRequests = state.cacheRequests;
      cacheRequests[action.data.lastSubmit.id] = {
        lastRequestStart: new Date().getTime(),
        trying: true
      }

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetProject: true
      }

    case 'RECEIVE_GET_PROJECT_FAIL':

      var cacheRequests = state.cacheRequests;
      cacheRequests[action.data.lastSubmit.id] = {
        fail: new Date().getTime(),
        trying: false
      }

       return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetProject: false,
        getProjectFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROJECT_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data
      }

      var cacheRequests = state.cacheRequests;
      cacheRequests[action.data.id] = {
        success: new Date().getTime(),
        trying: false
      }

      return {
        ...state,
        cache: cache,
        cacheRequests: cacheRequests,
        tryingToGetProject: false,
        getProjectSuccess: new Date().getTime()
      }




    case 'REQUEST_GET_PROJECT_TEAM':
      return {
        ...state,
        tryingToGetProjectTeam: true
      }

    case 'RECEIVE_GET_PROJECT_TEAM_FAIL':
       return {
        ...state,
        tryingToGetProjectTeam: false,
        getProjectTeamFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROJECT_TEAM_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].team = action.data.data;

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetProjectTeam: false,
        getProjectTeamSuccess: new Date().getTime()
      }




    case 'REQUEST_INSTALL_COMPONENT':
      return {
        ...state,
        tryingToInstallComponent: true,
        installComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INSTALL_COMPONENT_FAIL':
       return {
        ...state,
        tryingToInstallComponent: false,
        installComponentFail: new Date().getTime(),
        installComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INSTALL_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].components) cache[action.data.id].components = [];
      cache[action.data.id].components.unshift(action.data.data);

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToInstallComponent: false,
        installComponentSuccess: new Date().getTime(),
        installComponentForm: {errors: {}, lastSubmit: {}}
      }


    case 'REQUEST_UPDATE_INSTALLED_COMPONENT':

      var updateInstalledComponentRequests = state.updateInstalledComponentRequests;
      updateInstalledComponentRequests[action.data.lastSubmit.component_id] = {
        trying: true
      };

      return {
        ...state,
        // tryingToUpdateInstalledComponent: true,
        updateInstalledComponentRequests: updateInstalledComponentRequests,
        // updateinstalledComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INSTALLED_COMPONENT_FAIL':

      var updateInstalledComponentRequests = state.updateInstalledComponentRequests;
      updateInstalledComponentRequests[action.data.lastSubmit.component_id] = {
        trying: false,
        fail: new Date().getTime()
      }
       return {
        ...state,
        // tryingToUpdateInstalledComponent: false,
        updateInstalledComponentRequests: updateInstalledComponentRequests,
        // updateInstalledComponentFail: new Date().getTime(),
        // updateInstalledComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INSTALLED_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      
      var index = cache[action.data.id].components.findIndex(c => c.id === action.data.data.id);
      if(index > -1){
        cache[action.data.id].components[index] = action.data.data;
      }

      var updateInstalledComponentRequests = state.updateInstalledComponentRequests;
      updateInstalledComponentRequests[action.data.data.id] = {
        trying: false,
        success: new Date().getTime()
      };

      return {
        ...state,
        cache: cache,
        updateInstalledComponentRequests: updateInstalledComponentRequests,
        // tryingToUpdateInstalledComponent: false,
        // updateInstalledComponentSuccess: new Date().getTime(),
        // updateInstalledComponentForm: {errors: {}, lastSubmit: {}}
      }
    


    case 'REQUEST_RUN_SCHEDULED_COMPONENT':

      var runScheduledComponentRequests = state.runScheduledComponentRequests;
      runScheduledComponentRequests[action.data.lastSubmit.component_id] = {
        trying: true
      };

      return {
        ...state,
        // tryingToUpdateInstalledComponent: true,
        runScheduledComponentRequests: runScheduledComponentRequests,
        // updateinstalledComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RUN_SCHEDULED_COMPONENT_FAIL':

      var runScheduledComponentRequests = state.runScheduledComponentRequests;
      runScheduledComponentRequests[action.data.lastSubmit.component_id] = {
        trying: false,
        fail: new Date().getTime()
      }

      return {
        ...state,
        // tryingToUpdateInstalledComponent: false,
        runScheduledComponentRequests: runScheduledComponentRequests,
        // updateInstalledComponentFail: new Date().getTime(),
        // updateInstalledComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RUN_SCHEDULED_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      
      var index = cache[action.data.project_id].components.findIndex(c => c.id === action.data.data.id);
      if(index > -1){
        console.log('updating component', action.data.data.id);
        cache[action.data.project_id].components[index] = action.data.data;
      }

      var runScheduledComponentRequests = state.runScheduledComponentRequests;
      runScheduledComponentRequests[action.data.data.id] = {
        trying: false,
        success: new Date().getTime()
      };

    return {
      ...state,
      cache: cache,
      runScheduledComponentRequests: runScheduledComponentRequests,
      // tryingToUpdateInstalledComponent: false,
      // updateInstalledComponentSuccess: new Date().getTime(),
      // updateInstalledComponentForm: {errors: {}, lastSubmit: {}}
    }

  
    case 'REQUEST_DELETE_INSTALLED_COMPONENT':
      return {
        ...state,
        tryingToDeleteInstalledComponent: true,
        deleteInstalledComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INSTALLED_COMPONENT_FAIL':
        return {
        ...state,
        tryingToDeleteInstalledComponent: false,
        deleteInstalledComponentFail: new Date().getTime(),
        deleteInstalledComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INSTALLED_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].components) cache[action.data.id].components = [];

      var index = cache[action.data.id].components.findIndex(c => c.id === action.data.data.id);
      if(index > -1){
        cache[action.data.id].components.splice(index, 1);
      }

      return {
        ...state,
        cache: cache,
        tryingToDeleteInstalledComponent: false,
        deleteInstalledComponentSuccess: new Date().getTime(),
        deleteInstalledComponentForm: {errors: {}, lastSubmit: {}}
      }






    case 'REQUEST_INSTALL_KNOWLEDGE':
      return {
        ...state,
        tryingToInstallKnowledge: true,
        installKnowledgeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INSTALL_KNOWLEDGE_FAIL':
       return {
        ...state,
        tryingToInstallKnowledge: false,
        installKnowledgeFail: new Date().getTime(),
        installKnowledgeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_INSTALL_KNOWLEDGE_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].knowledge) cache[action.data.id].knowledge = [];
      cache[action.data.id].knowledge.unshift(action.data.data);

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToInstallKnowledge: false,
        installKnowledgeSuccess: new Date().getTime(),
        installKnowledgeForm: {errors: {}, lastSubmit: {}}
      }

    



    case 'REQUEST_UPDATE_INSTALLED_KNOWLEDGE':
      return {
        ...state,
        tryingToUpdateInstalledKnowledge: true,
        updateinstalledKnowledgeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INSTALLED_KNOWLEDGE_FAIL':
        return {
        ...state,
        tryingToUpdateInstalledKnowledge: false,
        updateInstalledKnowledgeFail: new Date().getTime(),
        updateInstalledKnowledgeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_INSTALLED_KNOWLEDGE_SUCCESS':
      
      var cache = state.cache;
      
      var index = cache[action.data.id].knowledge.findIndex(c => c.id === action.data.data.id);
      if(index > -1){
        cache[action.data.id].knowledge[index] = action.data.data;
      }

      return {
        ...state,
        cache: cache,
        tryingToUpdateInstalledKnowledge: false,
        updateInstalledKnowledgeSuccess: new Date().getTime(),
        updateInstalledKnowledgeForm: {errors: {}, lastSubmit: {}}
      }

  
    case 'REQUEST_DELETE_INSTALLED_KNOWLEDGE':
      return {
        ...state,
        tryingToDeleteInstalledKnowledge: true,
        deleteinstalledKnowledgeForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INSTALLED_KNOWLEDGE_FAIL':
        return {
        ...state,
        tryingToDeleteInstalledKnowledge: false,
        deleteInstalledKnowledgeFail: new Date().getTime(),
        deleteInstalledKnowledgeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_INSTALLED_KNOWLEDGE_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].knowledge) cache[action.data.id].knowledge = [];

      var index = cache[action.data.id].knowledge.findIndex(c => c.id === action.data.data.id);
      if(index > -1){
        cache[action.data.id].knowledge.splice(index, 1);
      }

      return {
        ...state,
        cache: cache,
        tryingToDeleteInstalledKnowledge: false,
        deleteInstalledKnowledgeSuccess: new Date().getTime(),
        deleteInstalledKnowledgeForm: {errors: {}, lastSubmit: {}}
      }












    case 'REQUEST_SET_PROJECT_SETTINGS':

      return {
        ...state,
        tryingToSetProjectSettings: true,
        setProjectSettingsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_PROJECT_SETTINGS_FAIL':
       return {
        ...state,
        tryingToSetProjectSettings: false,
        setProjectSettingsFail: new Date().getTime(),
        setProjectSettingsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_PROJECT_SETTINGS_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id].display_name = action.data.display_name;
      cache[action.data.id].description = action.data.description;
      cache[action.data.id].visibility = action.data.visibility;

      return {
        ...state,
        cache: cache,
        tryingToSetProjectSettings: false,
        setProjectSettingsSuccess: new Date().getTime(),
        setProjectSettingsForm: {errors: {}, lastSubmit: {}}
      }






    case 'REQUEST_GET_PROJECT_ACTIVITY_LOG':
      return {
        ...state,
        tryingToGetProjectActivityLog: true,
      }

    case 'RECEIVE_GET_PROJECT_ACTIVITY_LOG_FAIL':
      return {
        ...state,
        tryingToGetProjectActivityLog: false,
        getProjectActivityLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROJECT_ACTIVITY_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].activityLogEmpty = false;
        if(!cache[action.data.id].activityLog || cache[action.data.id].activityLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].activityLog = action.data.data;
        } else {
          if(cache[action.data.id].activityLog[0].id !== action.data.data[0].id){
            cache[action.data.id].activityLog = cache[action.data.id].activityLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].activityLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].activityLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetProjectActivityLog: false,
        getProjectActivityLogSuccess: new Date().getTime(),
        cache: cache
      }




    case 'REQUEST_GET_PROJECT_API_LOG':
      return {
        ...state,
        tryingToGetProjectAPILog: true,
      }

    case 'RECEIVE_GET_PROJECT_API_LOG_FAIL':
      return {
        ...state,
        tryingToGetProjectAPILog: false,
        getProjectAPILogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PROJECT_API_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].apiLogEmpty = false;
        if(!cache[action.data.id].apiLog || cache[action.data.id].apiLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].apiLog = action.data.data;
        } else {
          if(cache[action.data.id].apiLog[0].id !== action.data.data[0].id){
            cache[action.data.id].apiLog = cache[action.data.id].apiLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].apiLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].apiLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetProjectAPILog: false,
        getProjectAPILogSuccess: new Date().getTime(),
        cache: cache
      }



    case 'REQUEST_GET_API_KEYS':
      return {
        ...state,
        tryingToGetAPIKeys: true,
      }

    case 'RECEIVE_GET_API_KEYS_FAIL':
      return {
        ...state,
        tryingToGetAPIKeys: false,
        getAPIKeysFail: new Date().getTime()
      }

    case 'RECEIVE_GET_API_KEYS_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].api_keys_empty = false;
        if(!cache[action.data.id].api_keys || cache[action.data.id].api_keys.length === 0 || !action.data.startedAfter){
          cache[action.data.id].api_keys = action.data.data;
        } else {
          if(cache[action.data.id].api_keys[0].id !== action.data.data[0].id){
            cache[action.data.id].api_keys = cache[action.data.id].api_keys.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].api_keys_empty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 25){
        cache[action.data.id].activityLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetAPIKeys: false,
        getAPIKeysSuccess: new Date().getTime(),
        cache: cache
      }




    case 'REQUEST_CREATE_API_KEY':
      return {
        ...state,
        tryingToCreateAPIKey: true,
        createAPIKeyForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_API_KEY_FAIL':
       return {
        ...state,
        tryingToCreateAPIKey: false,
        createAPIKeyFail: new Date().getTime(),
        createAPIKeyForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_API_KEY_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].api_keys) cache[action.data.id].api_keys = [];
      cache[action.data.id].api_keys.unshift(action.data.data);

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToCreateAPIKey: false,
        createAPIKeySuccess: new Date().getTime(),
        createAPIKeyForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_UPDATE_API_KEY':
      return {
        ...state,
        tryingToUpdateAPIKey: true,
        updateAPIKeyForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_API_KEY_FAIL':
       return {
        ...state,
        tryingToUpdateAPIKey: false,
        updateAPIKeyFail: new Date().getTime(),
        updateAPIKeyForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_UPDATE_API_KEY_SUCCESS':
      
      var cache = state.cache;
      if(!cache[action.data.id].api_keys){
        cache[action.data.id].api_keys = [];
        cache[action.data.id].api_keys.unshift(action.data.data);
      } else {
        var index = cache[action.data.id].api_keys.findIndex(k => k.id === action.data.data.id);
        if(index > -1){
          cache[action.data.id].api_keys[index] = action.data.data;
        }
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToUpdateAPIKey: false,
        updateAPIKeySuccess: new Date().getTime(),
        updateAPIKeyForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_REVOKE_API_KEY':
      return {
        ...state,
        tryingToRevokeAPIKey: true,
        revokeAPIKeyForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REVOKE_API_KEY_FAIL':
       return {
        ...state,
        tryingToRevokeAPIKey: false,
        revokeAPIKeyFail: new Date().getTime(),
        revokeAPIKeyForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_REVOKE_API_KEY_SUCCESS':
      
      var cache = state.cache;
      var key_index = (cache[action.data.id].api_keys || []).findIndex(k => k.id === action.data.data.id);
      if(key_index > -1){
        cache[action.data.id].api_keys.splice(key_index, 1);
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToRevokeAPIKey: false,
        revokeAPIKeySuccess: new Date().getTime(),
        revokeAPIKeyForm: {errors: {}, lastSubmit: {}}
      }

    
    case 'REQUEST_QUERY_PROJECT_DATA':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.lastSubmit.cacheID]){
        queryResultsCache[action.data.lastSubmit.cacheID] = {}
      }
      queryResultsCache[action.data.lastSubmit.cacheID][action.data.lastSubmit.query] = {
        trying: true
      }
      
      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }

    case 'RECEIVE_QUERY_PROJECT_DATA_FAIL':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.lastSubmit.cacheID]){
        queryResultsCache[action.data.lastSubmit.cacheID] = {}
      }
      queryResultsCache[action.data.lastSubmit.cacheID][action.data.lastSubmit.query] = {
        trying: false,
        fail: new Date().getTime()
      }
      
      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }

    case 'RECEIVE_QUERY_PROJECT_DATA_SUCCESS':

      var queryResultsCache = state.queryResultsCache;
      if(!queryResultsCache[action.data.cacheID]){
        queryResultsCache[action.data.cacheID] = {}
      }

      queryResultsCache[action.data.cacheID][action.data.data.query] = {
        trying: false,
        success: new Date().getTime(),
        data: action.data.data.data
      }

      return {
        ...state,
        queryResultsCache: queryResultsCache,
      }






    default:
      return state
  }
}

import updateObjectByPath from 'utilities/updateObjectByPath';


const safelyCacheComponent = (cache, data) => {

  if(cache[data.id]){

    // there is a chance we are already interacting with this component directly, if so we can ignore this update
    if(cache[data.id].socketUpdated){
      // do nothing
    }  else {


      let component = cache[data.id];

      if(!component.versions) component.versions = [];

      // nicely merge the old and new version arrays
      let versionList = component.versions;
      if(data.versions){
        for(const version of data.versions){
          var versionIndex = versionList.findIndex(v => v.id === version.id);
          if(versionIndex > -1 ){
            versionList[versionIndex] = {
              ...versionList[versionIndex],
              ...version,
              lastGetSuccess: new Date().getTime()
            }
          } else {
            versionList.push({
              ...version,
              lastGetSuccess: new Date().getTime()
            })
          }
        }
      }


      cache[data.id] = {
        ...component,
        ...data
      }

      cache[data.id].versions = versionList;
    }

  } else {
    cache[data.id] = data;
  }

  if(data.nested_dictionary){
    for(var i in data.nested_dictionary){
      let d = data.nested_dictionary[i];
      cache = safelyCacheComponent(cache, d);
    }
  }


  return cache;
}


export const componentReducer = (state = {
  cache: {},
  publicCache: {},
  demoCache: {},
  historyCache: {},
  linkCache: {},
  nodeOutputCache: {},
  cacheRequests: [],
  versionedCacheRequests: [],
  activeTestRequests: {},
  orgItems: {},
  myComponents: [],
  explainFlowNodesForm:           {errors: {}, lastSubmit: {}},
  newComponentForm:              {errors: {}, lastSubmit: {}},
  setComponentSettingsForm:    {errors: {}, lastSubmit: {}},
  // setComponentMetadataForm:      {errors: {}, lastSubmit: {}},
  setComponentMemoryOptionsForm:      {errors: {}, lastSubmit: {}},
  setComponentContextOptionsForm:      {errors: {}, lastSubmit: {}},
  setComponentModelForm:      {errors: {}, lastSubmit: {}},
  // setComponentDemoOptionsForm: {errors: {}, lastSubmit: {}},
  deleteComponentForm: {errors: {}, lastSubmit: {}},
  publishComponentForm:      {errors: {}, lastSubmit: {}},
  authenticateDemoForm:      {errors: {}, lastSubmit: {}},
  setComponentQualityControlForm:      {errors: {}, lastSubmit: {}},

  // we need these broken out because the UI shows multiple buttons
  tryingToRestoreComponentContent: {},
  restoreComponentContentSuccess: {},
  restoreComponentContentFail: {},
  demoComponentForm: {errors: {}, lastSubmit: {}},
  testComponentForm: {errors: {}, lastSubmit: {}},
  getComponentVersionTypePageForm: {errors: {}, lastSubmit: {}},
  demoResponseProgress: [],
  demoResponseConcat: "",
  testResponseProgress: [],
  wasMostRecentDemoStreamed: false,
  wasMostRecentTestStreamed: false,

  downloads: {},
  history: {},

  ready: false
}, action) => {

  switch(action.type){

    
    case 'SET_CURRENT_COMPONENT':
      return {
        ...state,
        current: action.data
      }


    case 'UNSET_CURRENT_COMPONENT':
      return {
        ...state,
        current: undefined
      }


    case 'PASS_PROFILE_COMPONENTS_TO_CACHE':
      var cache = state.cache;

      for(var i in action.data){
        cache = safelyCacheComponent(cache, action.data[i]);
      }

      return {
        ...state,
        cache: cache
      }



    case 'CLEAR_COMPONENT_UNDO_HISTORY':

      var history = state.history;
      delete history[action.data.id];
      
      return {
        ...state,
        history: history
      }



    case 'ADD_TO_COMPONENT_UNDO_HISTORY':

      var history = state.history;

      // if we have history for this component
      if(history[action.data.id]){

        // if we are currently back in time
        if(history[action.data.id].index > 0){

          // trim everything that we undid off the change timeline
          history[action.data.id].changes.splice(0, history[action.data.id].index); 
        }

        // add this new change to the front of the list and reset the index
        history[action.data.id].changes.unshift(action.data.data);
        history[action.data.id].index = 0;

        if(history[action.data.id].changes.length > 100){
          history[action.data.id].changes.splice(100, 1);
        }

      } else {

        // otherwise create the new history packet
        history[action.data.id] = {
          changes: [action.data.data],
          index: 0
        }
      }

      return {
        ...state,
        history: history
      }


    
    case 'MOVE_COMPONENT_UNDO_HISTORY_INDEX':

      var history = state.history;

      // if we have history for this component
      if(history[action.data.id]){

        history[action.data.id].index += action.data.direction;

        if(history[action.data.id].index < 0){
          history[action.data.id].index = 0;
        }


        if(history[action.data.id].index > history[action.data.id].length - 1){
          history[action.data.id].index = history[action.data.id].length - 1;
        }


      } 

      return {
        ...state,
        history: history
      }

    case 'SET_COMPONENT_NEEDS_SAVE':

      var cache = state.cache;
      if(cache[action.data.id]){
        cache[action.data.id].needsSave = action.data.needsSave;
      }

      return {
        ...state,
        cache: cache
      }

    case 'REQUEST_CREATE_NEW_COMPONENT':
      return {
        ...state,
        tryingToCreateNewComponent: true,
        newComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_COMPONENT_FAIL':
       return {
        ...state,
        tryingToCreateNewComponent: false,
        newComponentFail: new Date().getTime(),
        newComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_CREATE_NEW_COMPONENT_SUCCESS':
      var cache = state.cache;
      cache = safelyCacheComponent(cache, action.data);

      var myComponents = state.myComponents;
      if(!myComponents[action.data.scope]) myComponents[action.data.scope] = [];
      myComponents[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myComponents: myComponents,
        tryingToCreateNewComponent: false,
        newComponentSuccess: new Date().getTime(),
        newComponentForm: {errors: {}, lastSubmit: {}}
      }
    
    
    case 'REQUEST_COPY_COMPONENT':
      return {
        ...state,
        tryingToCopyComponent: true,
        copyComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_COPY_COMPONENT_FAIL':
        return {
        ...state,
        tryingToCopyComponent: false,
        copyComponentFail: new Date().getTime(),
        copyComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_COPY_COMPONENT_SUCCESS':
      var cache = state.cache;
      cache = safelyCacheComponent(cache, action.data);

      var myComponents = state.myComponents;
      if(!myComponents[action.data.scope]) myComponents[action.data.scope] = [];
      myComponents[action.data.scope].push(action.data);

      return {
        ...state,
        cache: cache,
        myComponents: myComponents,
        tryingToCopyComponent: false,
        copyComponentSuccess: new Date().getTime(),
        copyComponentForm: {errors: {}, lastSubmit: {}}
      }
  
    

    case 'REQUEST_GET_PUBLIC_PUBLISHED_COMPONENT':
      return {
        ...state,
        tryingToGetPublicPublishedComponent: true
      }

    case 'RECEIVE_GET_PUBLIC_PUBLISHED_COMPONENT_FAIL':
        return {
        ...state,
        tryingToGetPublicPublishedComponent: false,
        getPublicPublishedComponentFail: new Date().getTime()
      }

    case 'RECEIVE_GET_PUBLIC_PUBLISHED_COMPONENT_SUCCESS':
      
      var publicCache = state.publicCache;
      publicCache[action.data.id] = action.data;

      return {
        ...state,
        publicCache: publicCache,
        tryingToGetPublicPublishedComponent: false,
        getPublicPublishedComponentSuccess: new Date().getTime()
      }
  

    case 'REQUEST_GET_COMPONENTS_BY_ORGANIZATION':
      return {
        ...state,
        tryingToGetComponentsByOrganization: true
      }

    case 'RECEIVE_GET_COMPONENTS_BY_ORGANIZATION_FAIL':
       return {
        ...state,
        tryingToGetComponentsByOrganization: false,
        getOrganizationComponentsFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENTS_BY_ORGANIZATION_SUCCESS':
      
      var cache = state.cache;
      var orgItems = state.orgItems;
      for(var i in action.data){
        let p = action.data[i];

        cache = safelyCacheComponent(cache, p);
        // cache[p.id] = {
        //   ...cache[p.id],
        //   ...p
        // };

        if(!orgItems[p.scope]) orgItems[p.scope] = [];
        if(!orgItems[p.scope].find(c => c.id === p.id)){
          orgItems[p.scope].push(p.id);
        }
      }


      return {
        ...state,
        cache: cache,
        orgItems: orgItems,
        ready: true,
        tryingToGetComponentsByOrganization: false,
        getOrganizationComponentsSuccess: new Date().getTime()
      }

    

    case 'REQUEST_GET_MY_COMPONENTS':
      return {
        ...state,
        tryingToGetMyComponents: true
      }

    case 'RECEIVE_GET_MY_COMPONENTS_FAIL':
        return {
        ...state,
        tryingToGetMyComponents: false,
        getMyComponentsFail: new Date().getTime()
      }

    case 'RECEIVE_GET_MY_COMPONENTS_SUCCESS':
      
      var cache = state.cache;
      for(var i in action.data){
        let p = action.data[i];

        cache = safelyCacheComponent(cache, p);
        // cache[p.id] = {
        //   ...cache[p.id],
        //   ...p
        // };
      }

      return {
        ...state,
        cache: cache,
        ready: true,
        tryingToGetMyComponents: false,
        getMyComponentsSuccess: new Date().getTime()
      }
  



    case 'REQUEST_INCREMENT_COMPONENT_DOWNLOAD_STAT':

      var downloads = state.downloads;
      downloads[action.data.lastSubmit.id + "v" + action.data.lastSubmit.version] = true;

      return {
        ...state,
        downloads: downloads,
        tryingToIncrementComponentDownloadStat: true
      }

    case 'RECEIVE_INCREMENT_COMPONENT_DOWNLOAD_STAT_FAIL':
       return {
        ...state,
        tryingToIncrementComponentDownloadStat: false,
        incrementComponentDownloadFail: new Date().getTime()
      }

    case 'RECEIVE_INCREMENT_COMPONENT_DOWNLOAD_STAT_SUCCESS':
      
      return {
        ...state,
        tryingToIncrementComponentDownloadStat: false,
        incrementComponentDownloadSuccess: new Date().getTime()
      }

    


    case 'REQUEST_UPDATE_COMPONENT_CONTENT_ORDER':

    var cacheRequests = state.cacheRequests;
    cacheRequests.push(action.data.lastSubmit.id);

    return {
      ...state,
      updateComponentContentOrderForm: {errors: {}, lastSubmit: action.data.lastSubmit},
      tryingToUpdateComponentContentOrder: true
    }

  case 'RECEIVE_UPDATE_COMPONENT_CONTENT_ORDER_FAIL':
     return {
      ...state,
      tryingToUpdateComponentContentOrder: false,
      updateComponentContentOrderForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
      updateComponentContentOrderFail: new Date().getTime()
    }

  case 'RECEIVE_UPDATE_COMPONENT_CONTENT_ORDER_SUCCESS':
    
    var cache = state.cache;
    var component = cache[action.data.id];

    var draftVersionIndex = component.versions.findIndex(v => v.id === 'draft');
    if(draftVersionIndex > -1){
      component.versions[draftVersionIndex][action.data.type] = action.data.contentList;
    } else {
      component.versions.unshift({
        id: 'draft',
        [action.data.type]: action.data.contentList
      })
    }

    cache[action.data.id] = component;

    return {
      ...state,
      cache: cache,
      updateComponentContentOrderForm: {errors: {}, lastSubmit: {}},
      tryingToUpdateComponentContentOrder: false,
      updateComponentContentOrderSuccess: new Date().getTime()
    }





    case 'REQUEST_GET_COMPONENT':

      var cacheRequests = state.cacheRequests;
      cacheRequests.push(action.data.lastSubmit.id);

      return {
        ...state,
        cacheRequests: cacheRequests,
        tryingToGetComponent: true
      }

    case 'RECEIVE_GET_COMPONENT_FAIL':
       return {
        ...state,
        tryingToGetComponent: false,
        getComponentFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      cache = safelyCacheComponent(cache, action.data);
      // cache[action.data.id] = {
      //   ...cache[action.data.id],
      //   ...action.data
      // }

      return {
        ...state,
        cache: cache,
        tryingToGetComponent: false,
        getComponentSuccess: new Date().getTime()
      }

    

    case 'REQUEST_GET_COMPONENT_VERSION':

      var versionedCacheRequests = state.versionedCacheRequests;
      versionedCacheRequests.push(action.data.lastSubmit.id + 'v' + action.data.lastSubmit.version);

      return {
        ...state,
        versionedCacheRequests: versionedCacheRequests,
        tryingToGetComponentVersion: true
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_FAIL':
       return {
        ...state,
        tryingToGetComponentVersion: false,
        getComponentVersionFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_SUCCESS':
      
      var cache = state.cache;
      cache = safelyCacheComponent(cache, action.data);

      return {
        ...state,
        cache: cache,
        tryingToGetComponentVersion: false,
        getComponentVersionSuccess: new Date().getTime()
      }




    case 'REQUEST_GET_COMPONENT_VERSION_TYPE_PAGE':


      return {
        ...state,
        getComponentVersionTypeForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        tryingToGetComponentVersionTypePage: true
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_TYPE_PAGE_FAIL':
       return {
        ...state,
        tryingToGetComponentVersionTypePage: false,
        getComponentVersionTypeForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit},
        getComponentVersionTypePageFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_TYPE_PAGE_SUCCESS':
        
      var cache = state.cache;
      var component = cache[action.data.component_id];
      var versionIndex = component.versions.findIndex(v => v.id === action.data.version);

      // add the new data to the old and make sure we don't dupe on ID
      for(var i in action.data.data){
        let d = action.data.data[i];
        if(!component.versions[versionIndex][action.data.type].find(e => e.id === d.id)){
          component.versions[versionIndex][action.data.type].push(d);
        }
      }

      cache[action.data.component_id] = component;


      return {
        ...state,
        cache: cache,
        tryingToGetComponentVersionTypePage: false,
        getComponentVersionTypeForm: {errors: {}, lastSubmit: {}},
        getComponentVersionTypePageSuccess: new Date().getTime()
      }




    case 'REQUEST_GET_COMPONENT_VERSION_TO_DEMO':

      var versionedCacheRequests = state.versionedCacheRequests;
      versionedCacheRequests.push(action.data.lastSubmit.id + 'v' + action.data.lastSubmit.version);

      return {
        ...state,
        versionedCacheRequests: versionedCacheRequests,
        tryingToGetComponentVersionToDemo: true
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_TO_DEMO_FAIL':
       return {
        ...state,
        tryingToGetComponentVersionToDemo: false,
        getComponentVersionFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_VERSION_TO_DEMO_SUCCESS':
      
      var cache = state.cache;
      cache = safelyCacheComponent(cache, action.data);

      return {
        ...state,
        cache: cache,
        tryingToGetComponentVersionToDemo: false,
        getComponentVersionSuccess: new Date().getTime()
      }


    case 'REQUEST_SET_COMPONENT_MODEL':

      return {
        ...state,
        tryingToSetComponentModel: true,
        setComponentModelForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_MODEL_FAIL':
       return {
        ...state,
        tryingToSetComponentModel: false,
        setComponentModelFail: new Date().getTime(),
        setComponentModelForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_MODEL_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      
      if(draftIndex > -1){
        cache[action.data.id].versions[draftIndex] = {
          ...cache[action.data.id].versions[draftIndex],
          ...action.data.draft
        }
      } else {
        cache[action.data.id].versions.unshift(action.data.draft);
      }

      return {
        ...state,
        cache: cache,
        tryingToSetComponentModel: false,
        setComponentModelSuccess: new Date().getTime(),
        setComponentModelForm: {errors: {}, lastSubmit: {}}
      }


    
    case 'REQUEST_SET_COMPONENT_FLOW':

      return {
        ...state,
        tryingToSetComponentFlow: true,
        tryingToSetComponentFlowFlightTime: new Date().getTime(),
        setComponentFlowForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_FLOW_FAIL':
       return {
        ...state,
        tryingToSetComponentFlow: false,
        setComponentFlowFail: new Date().getTime(),
        setComponentFlowForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_FLOW_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      
      if(draftIndex > -1){
        // has there been a change since this was request was flighted?
        // check the socketUpdated time against the time this request was flighted
        if(cache[action.data.id].socketUpdated > state.tryingToSetComponentFlowFlightTime){
          // if so, don't update the draft
        } else {

          cache[action.data.id].versions[draftIndex] = {
            ...cache[action.data.id].versions[draftIndex],
            ...action.data.draft,
          }
        }
      } else {
        cache[action.data.id].versions.unshift(action.data.draft);
      }

      cache[action.data.id].needsSave = false;

      return {
        ...state,
        cache: cache,
        tryingToSetComponentFlow: false,
        setComponentFlowSuccess: new Date().getTime(),
        setComponentFlowForm: {errors: {}, lastSubmit: {}}
      }
  
    case 'BULK_UPDATE_COMPONENT':
      var cache = state.cache;
      
      var component = cache[action.data.id];
      

      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      if(draftIndex > -1){


        var changeHistory = state.historyCache[action.data.id];
        if(!changeHistory) changeHistory = {
          currentIndex: 0,
          changes: []
        }

        // if change history is back in time, trim the future
        if(changeHistory.currentIndex > -1){
          changeHistory.changes.splice(0, changeHistory.currentIndex);
          changeHistory.currentIndex = -1;
        }
        
        changeHistory.changes.unshift(JSON.parse(JSON.stringify(cache[action.data.id].versions[draftIndex])));

        if(changeHistory.changes.length > 100){
          // slice off the oldest change from the end
          changeHistory.changes.splice(changeHistory.changes.length - 1, 1);
        }

        // log out a summary of current history
        // console.log('change history', changeHistory.changes.length, changeHistory.currentIndex);
        
        // use the selector method to update the right part of the object
        for(var event of action.data.events){
          // use the jsonpath on action.data.event to update the object
          cache[action.data.id].versions[draftIndex] = updateObjectByPath({
            obj: cache[action.data.id].versions[draftIndex], 
            path: event.path, 
            changes: event.changes,
            operation: event.operation
          });
        }
        cache[action.data.id].socketUpdated = new Date().getTime();
      }

      return {
        ...state,
        cache: cache,
        historyCache: {
          ...state.historyCache,
          [action.data.id]: changeHistory
        }
      }

    case 'BULK_UPDATE_COMPONENT_FROM_SOCKET':
      var cache = state.cache;    
      
      var component = cache[action.data.id];


      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      if(draftIndex > -1){
        // use the selector method to update the right part of the object

        var changeHistory = state.historyCache[action.data.id];
        if(!changeHistory) changeHistory = {
          currentIndex: -1,
          changes: []
        }

        // if change history is back in time, trim the future
        if(changeHistory.currentIndex > -1){
          changeHistory.changes.splice(0, changeHistory.currentIndex);
          changeHistory.currentIndex = -1;
        }
        
        changeHistory.changes.unshift(JSON.parse(JSON.stringify(cache[action.data.id].versions[draftIndex])));

        if(changeHistory.changes.length > 100){
          // slice off the oldest change from the end
          changeHistory.changes.splice(changeHistory.changes.length - 1, 1);
        }

        for(var event of action.data.events){
          // use the jsonpath on action.data.event to update the object
          cache[action.data.id].versions[draftIndex] = updateObjectByPath({
            obj: cache[action.data.id].versions[draftIndex], 
            path: event.path, 
            changes: event.changes,
            operation: event.operation
          });
        }
        cache[action.data.id].socketUpdated = new Date().getTime();
      }

      return {
        ...state,
        cache: cache,
        historyCache: {
          ...state.historyCache,
          [action.data.id]: changeHistory
        }
      }

    // handle UNDO which should move the changeHistory index back one and replace the current draft with that draft
    case 'UNDO_BULK_UPDATE_COMPONENT':
      var cache = state.cache;
      var changeHistory = state.historyCache[action.data.id];

      console.log(changeHistory);

      if(changeHistory && changeHistory.currentIndex < changeHistory.changes.length - 1){
        
        changeHistory.currentIndex++;
        let index = cache[action.data.id].versions.findIndex(v => v.id === 'draft');

        if(index > -1){
          cache[action.data.id].versions[index] = changeHistory.changes[changeHistory.currentIndex];

          console.log('undoing', changeHistory.currentIndex, changeHistory.changes.length);
          console.log('updating draft to', changeHistory.changes[changeHistory.currentIndex]);
          cache[action.data.id].socketUpdated = new Date().getTime();
        }
      }

      return {
        ...state,
        cache: cache,
        historyCache: {
          ...state.historyCache,
          [action.data.id]: changeHistory
        }
      }

    // handle REDO which should move the changeHistory index forward one and replace the current draft with that draft
    case 'REDO_BULK_UPDATE_COMPONENT':
      var cache = state.cache;
      var changeHistory = state.historyCache[action.data.id];

      if(changeHistory && changeHistory.currentIndex > -1){
        changeHistory.currentIndex--;
        
        let index = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
        if(index > -1){
          console.log('redoing', changeHistory.currentIndex, changeHistory.changes.length);
          cache[action.data.id].versions[index] = changeHistory.changes[changeHistory.currentIndex];
          cache[action.data.id].socketUpdated = new Date().getTime();
        }
      }

      return {
        ...state,
        cache: cache,
        historyCache: {
          ...state.historyCache,
          [action.data.id]: changeHistory
        }
      }


    case 'REQUEST_SET_COMPONENT_MEMORY_OPTIONS':

      return {
        ...state,
        tryingToSetComponentMemoryOptions: true,
        setComponentMemoryOptionsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_MEMORY_OPTIONS_FAIL':
       return {
        ...state,
        tryingToSetComponentMemoryOptions: false,
        setComponentMemoryOptionsFail: new Date().getTime(),
        setComponentMemoryOptionsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_MEMORY_OPTIONS_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      
      if(draftIndex > -1){
        cache[action.data.id].versions[draftIndex] = {
          ...cache[action.data.id].versions[draftIndex],
          ...action.data.draft
        }
      } else {
        cache[action.data.id].versions.unshift(action.data.draft);
      }

      return {
        ...state,
        cache: cache,
        tryingToSetComponentMemoryOptions: false,
        setComponentMemoryOptionsSuccess: new Date().getTime(),
        setComponentMemoryOptionsForm: {errors: {}, lastSubmit: {}}
      }


      case 'REQUEST_SET_COMPONENT_CONTEXT_OPTIONS':

      return {
        ...state,
        tryingToSetComponentContextOptions: true,
        setComponentContextOptionsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_CONTEXT_OPTIONS_FAIL':
       return {
        ...state,
        tryingToSetComponentContextOptions: false,
        setComponentContextOptionsFail: new Date().getTime(),
        setComponentContextOptionsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_CONTEXT_OPTIONS_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      
      if(draftIndex > -1){
        cache[action.data.id].versions[draftIndex] = {
          ...cache[action.data.id].versions[draftIndex],
          ...action.data.draft
        }
      } else {
        cache[action.data.id].versions.unshift(action.data.draft);
      }

      return {
        ...state,
        cache: cache,
        tryingToSetComponentContextOptions: false,
        setComponentContextOptionsSuccess: new Date().getTime(),
        setComponentContextOptionsForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_SET_COMPONENT_SETTINGS':

      return {
        ...state,
        tryingToSetComponentSettings: true,
        setComponentSettingsForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_SETTINGS_FAIL':
       return {
        ...state,
        tryingToSetComponentSettings: false,
        setComponentSettingsFail: new Date().getTime(),
        setComponentSettingsForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SET_COMPONENT_SETTINGS_SUCCESS':
      
      var cache = state.cache;
      cache[action.data.id] = {
        ...cache[action.data.id],
        ...action.data.component
      }
      
      return {
        ...state,
        cache: cache,
        tryingToSetComponentSettings: false,
        setComponentSettingsSuccess: new Date().getTime(),
        setComponentSettingsForm: {errors: {}, lastSubmit: {}}
      }
    
    

    case 'REQUEST_EXPLAIN_FLOW_NODES':

      return {
        ...state,
        tryingToExplainFlowNodes: true,
        explainFlowNodesForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EXPLAIN_FLOW_NODES_FAIL':
       return {
        ...state,
        tryingToExplainFlowNodes: false,
        explainFlowNodesFail: new Date().getTime(),
        explainFlowNodesForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_EXPLAIN_FLOW_NODES_SUCCESS':
      
      var cache = state.cache;
      var explanations = cache[action.data.id].explanations || {};

      // make an ID out of the NODES_TO_EXPLAIN ids
      var id = action.data.nodes_to_explain.map(n => n.id).join('-');
      explanations[id] = action.data;

      cache[action.data.id] = {
        ...cache[action.data.id],
        explanations: explanations
      }
      
      return {
        ...state,
        cache: cache,
        tryingToExplainFlowNodes: false,
        explainFlowNodesSuccess: new Date().getTime(),
        explainFlowNodesForm: {errors: {}, lastSubmit: {}}
      }


    case 'CLEAR_TEMP_FLOW':

      var cache = state.cache;
      if(cache[action.data]){
        cache[action.data].tempFlow = undefined;
      }

      return {
        ...state,
        cache: cache
      }

    case 'REQUEST_GENERATE_FLOW':

      return {
        ...state,
        tryingToGenerateFlow: true,
        generateFlowForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_FLOW_FAIL':
       return {
        ...state,
        tryingToGenerateFlow: false,
        generateFlowFail: new Date().getTime(),
        generateFlowForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_GENERATE_FLOW_SUCCESS':
      
      var cache = state.cache;
      

      cache[action.data.id].tempFlow = {
        flow_nodes: action.data.flow_nodes,
        flow_links: action.data.flow_links,
        explanation: action.data.explanation
      }

      // cache[action.data.id] = {
      //   ...cache[action.data.id],
      //   flow_nodes: action.data.flow_nodes,
      //   flow_links: action.data.flow_links,
      //   // explanation: action.data.explanation
      // }
      
      return {
        ...state,
        cache: cache,
        tryingToGenerateFlow: false,
        generateFlowSuccess: new Date().getTime(),
        generateFlowForm: {errors: {}, lastSubmit: {}}
      }


    

    case 'REQUEST_DELETE_COMPONENT':

      return {
        ...state,
        tryingToDeleteComponent: true,
        deleteComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_COMPONENT_FAIL':
      return {
        ...state,
        tryingToDeleteComponent: false,
        deleteComponentFail: new Date().getTime(),
        deleteComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      delete cache[action.data.id];
      
      return {
        ...state,
        cache: cache,
        tryingToDeleteComponent: false,
        deleteComponentSuccess: new Date().getTime(),
        deleteComponentForm: {errors: {}, lastSubmit: {}}
      }




    case 'REQUEST_AUTHENTICATE_DEMO':

      return {
        ...state,
        tryingToAuthenticateDemo: true,
        authenticateDemoForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_AUTHENTICATE_DEMO_FAIL':
       return {
        ...state,
        tryingToAuthenticateDemo: false,
        authenticateDemoFail: new Date().getTime(),
        authenticateDemoForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_AUTHENTICATE_DEMO_SUCCESS':
      
      
      return {
        ...state,
        tryingToAuthenticateDemo: false,
        authenticateDemoSuccess: new Date().getTime(),
        authenticateDemoForm: {errors: {}, lastSubmit: {}},
        authenticatedDemos: action.data.data
      }


    case 'REQUEST_ADD_CONTENT_TO_COMPONENT':

      return {
        ...state,
        tryingToAddContentToComponent: action.data.lastSubmit.content_id ? false : true,
        tryingToMakeACopyOfContent: action.data.lastSubmit.content_id ? true : false,
        addContentToComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ADD_CONTENT_TO_COMPONENT_FAIL':
       return {
        ...state,
        tryingToAddContentToComponent: false,
        tryingToMakeACopyOfContent: false,
        addContentToComponentFail: new Date().getTime(),
        addContentToComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_ADD_CONTENT_TO_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      
      var draftIndex = (cache[action.data.id].versions || []).findIndex(v => v.id === 'draft');
      
      if(draftIndex > -1){
        if(!cache[action.data.id].versions[draftIndex][action.data.content.type]) cache[action.data.id].versions[draftIndex][action.data.content.type] = [];
        cache[action.data.id].versions[draftIndex][action.data.content.type].push(action.data.content.data);
      } 
    

      return {
        ...state,
        cache: cache,
        tryingToAddContentToComponent: false,
        tryingToMakeACopyOfContent: false,
        addContentToComponentSuccess: new Date().getTime(),
        addContentToComponentForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_SAVE_COMPONENT_CONTENT':

      return {
        ...state,
        tryingToSaveComponentContent: true,
        saveComponentContentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SAVE_COMPONENT_CONTENT_FAIL':
       return {
        ...state,
        tryingToSaveComponentContent: false,
        saveComponentContentFail: new Date().getTime(),
        saveComponentContentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_SAVE_COMPONENT_CONTENT_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      if(draftIndex > -1){
        if(!cache[action.data.id].versions[draftIndex][action.data.content.type]){
          cache[action.data.id].versions[draftIndex][action.data.content.type] = [action.data.content.data];
        } else {
          var contentIndex = cache[action.data.id].versions[draftIndex][action.data.content.type].findIndex(c => c.id === action.data.content.data.id);
          if(contentIndex > -1){
            cache[action.data.id].versions[draftIndex][action.data.content.type][contentIndex] = action.data.content.data;
          }
        }
      } 

      return {
        ...state,
        cache: cache,
        tryingToSaveComponentContent: false,
        saveComponentContentSuccess: new Date().getTime(),
        saveComponentContentForm: {errors: {}, lastSubmit: {}}
      }








    case 'REQUEST_RESTORE_COMPONENT_CONTENT':

      var trying = state.tryingToRestoreComponentContent;
      trying[action.data.lastSubmit.content_id] =  true;

      return {
        ...state,
        tryingToRestoreComponentContent: trying,
        restoreComponentContentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RESTORE_COMPONENT_CONTENT_FAIL':

      var trying = state.tryingToRestoreComponentContent;
      trying[action.data.lastSubmit.content_id] =  false;


      var fail = state.restoreComponentContentFail;
      fail[action.data.lastSubmit.content_id] =  new Date().getTime();

       return {
        ...state,
        tryingToRestoreComponentContent: trying,
        restoreComponentContentFail: fail,
        restoreComponentContentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_RESTORE_COMPONENT_CONTENT_SUCCESS':

      var trying = state.tryingToRestoreComponentContent;
      trying[action.data.content.data.id] =  false;

      var success = state.restoreComponentContentSuccess;
      success[action.data.content.data.id] =  new Date().getTime();
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      if(draftIndex > -1){
        if(!cache[action.data.id].versions[draftIndex][action.data.content.type]){
          cache[action.data.id].versions[draftIndex][action.data.content.type] = [action.data.content.data];
        } else {
          var contentIndex = cache[action.data.id].versions[draftIndex][action.data.content.type].findIndex(c => c.id === action.data.content.data.id);
          if(contentIndex > -1){
            cache[action.data.id].versions[draftIndex][action.data.content.type][contentIndex] = action.data.content.data;
          }
        }
      } 

      return {
        ...state,
        cache: cache,
        tryingToRestoreComponentContent: trying,
        restoreComponentContentSuccess: success,
        restoreComponentContentForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_DELETE_COMPONENT_CONTENT':

      return {
        ...state,
        tryingToDeleteComponentContent: true,
        deleteComponentContentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_COMPONENT_CONTENT_FAIL':
       return {
        ...state,
        tryingToDeleteComponentContent: false,
        deleteComponentContentFail: new Date().getTime(),
        deleteComponentContentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DELETE_COMPONENT_CONTENT_SUCCESS':
      
      var cache = state.cache;
      var draftIndex = cache[action.data.id].versions.findIndex(v => v.id === 'draft');
      if(draftIndex > -1){
        if(!cache[action.data.id].versions[draftIndex][action.data.content.type]){
          cache[action.data.id].versions[draftIndex][action.data.content.type] = [];
        } else {
          var contentIndex = cache[action.data.id].versions[draftIndex][action.data.content.type].findIndex(c => c.id === action.data.content.id);
          
          if(contentIndex > -1){
            cache[action.data.id].versions[draftIndex][action.data.content.type][contentIndex].deleted = true;
          }
        }
      } 

      return {
        ...state,
        cache: cache,
        tryingToDeleteComponentContent: false,
        deleteComponentContentSuccess: new Date().getTime(),
        deleteComponentContentForm: {errors: {}, lastSubmit: {}}
      }









    case 'REQUEST_PUBLISH_COMPONENT':

      return {
        ...state,
        tryingToPublishComponent: true,
        publishComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_PUBLISH_COMPONENT_FAIL':
       return {
        ...state,
        tryingToPublishComponent: false,
        publishComponentFail: new Date().getTime(),
        publishComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_PUBLISH_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      // cache[action.data.id] = action.data;
      cache = safelyCacheComponent(cache, action.data);

      return {
        ...state,
        cache: cache,
        tryingToPublishComponent: false,
        publishComponentSuccess: new Date().getTime(),
        publishComponentForm: {errors: {}, lastSubmit: {}}
      }





    case 'REQUEST_GET_COMPONENT_ACTIVITY_LOG':
      return {
        ...state,
        tryingToGetComponentActivityLog: true,
      }

    case 'RECEIVE_GET_COMPONENT_ACTIVITY_LOG_FAIL':
      return {
        ...state,
        tryingToGetComponentActivityLog: false,
        getComponentActivityLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_ACTIVITY_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].activityLogEmpty = false;
        if(!cache[action.data.id].activityLog || cache[action.data.id].activityLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].activityLog = action.data.data;
        } else {
          if(cache[action.data.id].activityLog[0].id !== action.data.data[0].id){
            cache[action.data.id].activityLog = cache[action.data.id].activityLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].activityLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].activityLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetComponentActivityLog: false,
        getComponentActivityLogSuccess: new Date().getTime(),
        cache: cache
      }

    case 'STOP_DEMO_COMPONENT':

      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;

      // find the most recent demo request and mark it as canceled
      if(demoCache[id]){
        for(var i = demoCache[id].length - 1; i >= 0; i--){
          if(demoCache[id][i].request){
            demoCache[id][i].canceled = true;
            // clear its response and updates
            demoCache[id][i].response = false;
            demoCache[id][i].updates = [];
            break;
          }
        }
      }

      return {
        ...state,
        demoCache: demoCache,
        tryingToDemoComponent: false,
      }

    case 'REMOVE_DEMO_CACHE_ITEM':

      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;

      // find the item by index and remove it
      if(demoCache[id]){
        if(demoCache[id][action.data.index]){
          if(action.data.whichHalf === 'request'){
            demoCache[id][action.data.index].request = false;
          } else {
            demoCache[id][action.data.index].updates = [];
            demoCache[id][action.data.index].response = false;
          }
        }
      }

      return {
        ...state,
        demoCache: demoCache
      }

    case 'REMOVE_DEMO_CACHE_AFTER_INDEX':

      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;

      // find the items after the index and remove them completely
      if(demoCache[id]){
        while(demoCache[id].length > action.data.index){
          demoCache[id].splice(action.data.index, 1);
        }
      }

      return {
        ...state,
        demoCache: demoCache
      }


    case 'REQUEST_DEMO_COMPONENT':

      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;
      if(!demoCache[id]) demoCache[id] = [];
      demoCache[id].push({
        request: {
          ...action.data,
          timestamp: new Date().toISOString()
        },
        response: false,
        error: false
      });

      return {
        ...state,
        tryingToDemoComponent: true,
        wasMostRecentDemoStreamed: false,
        demoResponseProgress: [],
        demoResponseConcat: "",
        demoStartedAt: new Date().getTime(),
        demoComponentStreamError: undefined,
        demoComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DEMO_COMPONENT_FAIL':

      var demoCache = state.demoCache;
      var id = action.data.lastSubmit.id + '_' + action.data.lastSubmit.version;
      if(!demoCache[id]) demoCache[id] = [];
      demoCache[id][demoCache[id].length - 1].error = action.data;

      return {
        ...state,
        demoCache: demoCache,
        tryingToDemoComponent: false,
        demoResponseProgress: [],
        demoResponseConcat: "",
        demoComponentFail: new Date().getTime(),
        demoComponentForm: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
      }

    case 'RECEIVE_DEMO_COMPONENT_SUCCESS':

      var demoCache = state.demoCache;
      var id = action.data.agent.id + '_' + action.data.agent.version;
      if(!demoCache[id]) demoCache[id] = [];
      // if it hasnt been canceled, add the response
      if(!demoCache[id][demoCache[id].length - 1].canceled){
        demoCache[action.data.agent.id + '_' + action.data.agent.version][demoCache[id].length - 1].response = action.data;
      }
      
      return {
        ...state,
        demoCache: demoCache,
        demoResponse: action.data,
        demoResponseProgress: [],
        demoResponseConcat: "",
        wasMostRecentDemoStreamed: false,
        tryingToDemoComponent: false,
        demoComponentSuccess: new Date().getTime(),
        demoComponentForm: {errors: {}, lastSubmit: {}}
      }

    
    case 'DEMO_STREAM_START':

      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;
      if(!demoCache[id]) demoCache[id] = [];
      demoCache[id].push({
        stream: true,
        request: {
          ...action.data.data,
          timestamp: new Date().toISOString()
        },
        updates: [],
        response: false,
        error: false
      });

      return {
        ...state,
        tryingToDemoComponent: true,
        wasMostRecentDemoStreamed: true,
        demoStartedAt: new Date().getTime(),
        demoResponseProgress: [],
        demoResponseConcat: "",
        demoComponentStreamError: undefined,
        demoComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit},
        demoComponentForm: {errors: {}, lastSubmit: {}}
      }

    case 'DEMO_STREAM_UPDATE':

      let newDemoResponseConcat = state.demoResponseConcat;
      var demoCache = state.demoCache;

      if(action.data.dataPacket){
        // if it contains a partial_content, add it
        if(action.data.dataPacket.partial_content){
          newDemoResponseConcat += action.data.dataPacket.partial_content;
        }

        var id = action.data.id + '_' + action.data.version;
        if(!demoCache[id]) demoCache[id] = [];
        
        // if it hasn't been canceled, add the response
        if(!demoCache[id][demoCache[id].length - 1].canceled){
          demoCache[id][demoCache[id].length - 1].updates.push(action.data);
        }
      }

      return {
        ...state,
        demoCache: demoCache,
        demoResponseProgress: [...state.demoResponseProgress, action.data.dataPacket],
        demoResponseConcat: newDemoResponseConcat,
        tryingToDemoComponent: true,
        wasMostRecentDemoStreamed: true,
        demoComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'DEMO_BULK_STREAM_UPDATE':
        
      let newDemoResponseConcatBulk = state.demoResponseConcat;
      var demoCache = state.demoCache;
      var id = action.data.id + '_' + action.data.version;
      if(!demoCache[id]) demoCache[id] = [];

      // if it hasn't been canceled, add the response
      if(!demoCache[id][demoCache[id].length - 1].canceled){
        for(var i in action.data.updates){
          if(action.data.updates[i].dataPacket.partial_content){
            newDemoResponseConcatBulk += action.data.updates[i].dataPacket.partial_content;
          }

          demoCache[id][demoCache[id].length - 1].updates.push(action.data.updates[i].dataPacket);
        }
      }

      return {
        ...state,
        demoCache: demoCache,
        demoResponseProgress: state.demoResponseProgress.concat(action.data.updates.map(d => d.dataPacket)),
        demoResponseConcat: newDemoResponseConcatBulk,
        tryingToDemoComponent: true,
        wasMostRecentDemoStreamed: true,
        demoComponentForm: {errors: {}, lastSubmit: action.data.lastSubmit}
      }

    case 'DEMO_STREAM_ERROR':

      var demoCache = state.demoCache;
      var id = action.error.id + '_' + action.error.version;
      if(!demoCache[id]) demoCache[id] = [];

      if(!demoCache[id][demoCache[id].length - 1].canceled){
        demoCache[id][demoCache[id].length - 1].error = action.error.dataPacket;
      }

      return {
        ...state,
        demoCache: demoCache,
        tryingToDemoComponent: false,
        wasMostRecentDemoStreamed: true,
        demoComponentFail: new Date().getTime(),
        demoComponentStreamError: action.error,
        demoComponentForm: {errors: {}, lastSubmit: {}}
      }

    case 'DEMO_STREAM_COMPLETE':

      var demoCache = state.demoCache;

      var id = action.data.dataPacket.agent.id + '_' + action.data.dataPacket.agent.version;
      if(!demoCache[id]) demoCache[id] = [];

      // if it hasn't been canceled, add the response
      var linkCache = state.linkCache;
      var nodeOutputCache = state.nodeOutputCache;

      if(!demoCache[id][demoCache[id].length - 1].canceled){
        
        linkCache[action.data.dataPacket.agent.id] = {
          ...linkCache[action.data.dataPacket.agent.id],
          ...action.data.dataPacket.verbose.link_cache
        }

        
        if(action.data.dataPacket.verbose.link_cache){
          if(!nodeOutputCache[action.data.dataPacket.agent.id]) nodeOutputCache[action.data.dataPacket.agent.id] = {};

          // for each
          for(var link_id in action.data.dataPacket.verbose.link_cache){
            // split by -> to get the from and to
            var link = link_id.split(" -> ");
            nodeOutputCache[action.data.dataPacket.agent.id][link[0]] = action.data.dataPacket.verbose.link_cache[link_id];
          }
        }

        demoCache[id][demoCache[id].length - 1].response = action.data.dataPacket;
      }
      
      return {
        ...state,
        demoCache: demoCache,
        linkCache: linkCache,
        nodeOutputCache: nodeOutputCache,
        demoResponse: action.data.dataPacket,
        demoResponseConcat: undefined,
        tryingToDemoComponent: false,
        wasMostRecentDemoStreamed: true,
        demoComponentSuccess: new Date().getTime(),
        demoComponentForm: {errors: {}, lastSubmit: {}}
      }

    case 'CLEAR_LINK_CACHE':

      var linkCache = state.linkCache;
      delete linkCache[action.data.component_id][action.data.link_id];

      return {
        ...state,
        linkCache: linkCache
      }

    
    case 'DEMO_STREAM_CLOSED':
      
      return {
        ...state,
        tryingToDemoComponent: false,
        demoComponentForm: {errors: {}, lastSubmit: {}}
      }



      case 'REQUEST_AGENT_TEST_COMPONENT':

      return {
        ...state,
        activeTestRequests: {
          ...state.activeTestRequests,
          [action.data.lastSubmit.test_id]: {
            trying: true,
            startedAt: new Date().getTime(),
            form: {errors: {}, lastSubmit: action.data.lastSubmit}
          }
        },
        wasMostRecentTestStreamed: false,
      }

    case 'RECEIVE_AGENT_TEST_COMPONENT_FAIL':
       return {
        ...state,
        activeTestRequests: {
          ...state.activeTestRequests,
          [action.data.lastSubmit.test_id]: {
            trying: false,
            fail: new Date().getTime(),
            form: {errors: action.data.errors, lastSubmit: action.data.lastSubmit}
          }
        },
      }

    case 'RECEIVE_AGENT_TEST_COMPONENT_SUCCESS':
      
      var cache = state.cache;
      var versionIndex = cache[action.data.request.id].versions.findIndex(v => v.id === action.data.request.version);
      var test_index = cache[action.data.request.id].versions[versionIndex].tests.findIndex(t => t.id === action.data.request.test_id);
      if(test_index > -1){
        cache[action.data.request.id].versions[versionIndex].tests[test_index].last_test_result = action.data.response;
        // cache[action.data.request.id].versions[versionIndex].tests[test_index].last_test_analysis = action.data.analysis;
      }

      return {
        ...state,
        cache: cache,
        activeTestRequests: {
          ...state.activeTestRequests,
          [action.data.request.test_id]: {
            trying: false,
            success: new Date().getTime(),
            form: {errors: {}, lastSubmit: {}}
          }
        },
      }




    case 'REQUEST_GET_COMPONENT_ACTIVITY_LOG':
      return {
        ...state,
        tryingToGetComponentActivityLog: true,
      }

    case 'RECEIVE_GET_COMPONENT_ACTIVITY_LOG_FAIL':
      return {
        ...state,
        tryingToGetComponentActivityLog: false,
        getComponentActivityLogFail: new Date().getTime()
      }

    case 'RECEIVE_GET_COMPONENT_ACTIVITY_LOG_SUCCESS':

      var cache = state.cache;

      if(action.data.data.length > 0){
        cache[action.data.id].activityLogEmpty = false;
        if(!cache[action.data.id].activityLog || cache[action.data.id].activityLog.length === 0 || !action.data.startedAfter){
          cache[action.data.id].activityLog = action.data.data;
        } else {
          if(cache[action.data.id].activityLog[0].id !== action.data.data[0].id){
            cache[action.data.id].activityLog = cache[action.data.id].activityLog.concat(action.data.data);
          }
        }
      } else {
        cache[action.data.id].activityLogEmpty = true;
      }

      // if an incomplete page is returned theres no more to get...
      if(action.data.data.length < 10){
        cache[action.data.id].activityLogEmpty = true; 
      }

      return {
        ...state,
        tryingToGetComponentActivityLog: false,
        getComponentActivityLogSuccess: new Date().getTime(),
        cache: cache
      }




    default:
      return state
  }
}
